import { ethers } from "ethers";
import Big from "big.js";

export const convertFromExponentialToDecimal = (n: any): any => {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
};

export const  getDecimalsByChainId=(chainId: any)=> {
    if(chainId == "1"){
        return 18;
    }else {
        return 18;
    }
   };

 export const  decimalsIntoNumber=(amount: any, chainId: any)=> {
    const bigNumberValue = ethers.BigNumber.from(amount.toString());
    let formattedValue:any = ethers.utils.formatUnits(bigNumberValue, getDecimalsByChainId(chainId));
    formattedValue = convertFromExponentialToDecimal(
      formattedValue.toString()
    );
    console.log(formattedValue,"formattedValue")
   return formattedValue;
  };

  export const numberIntoDecimals = (amount: any, chainId: any) => {
    amount = Big(amount);
    let decimal = Big(10 ** Number(getDecimalsByChainId(chainId)));
    let amountFormatted = amount.mul(decimal);
    amountFormatted = convertFromExponentialToDecimal(
      amountFormatted.toString()
    );
    if (amountFormatted.includes(".")) {
        amountFormatted = amountFormatted.split(".")[0];
    }
    console.log(amountFormatted,"formattedValue")
    return amountFormatted;
  };
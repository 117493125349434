import {
  getAccount,
  switchChain,
  waitForTransactionReceipt,
  writeContract,
} from "@wagmi/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAccount, useBalance, useReadContract } from "wagmi";
import Web3 from "web3";
import { authSlice } from "../redux/apis/apisSlics";
import { RootState } from "../redux/rootReducer";
import { abi } from "./abi";
import { config } from "./config";
import { Images } from "./Config/Images";
import { swapAbi } from "./swapAbi";
import Big from "big.js";
import { decimalsIntoNumber, numberIntoDecimals } from "./const.utils";
import { ethers } from "ethers";
import {
  createTransactionComplete,
  createTransactionFail,
  createTransactionStart,
  createWalletTransaction,
} from "./swapCrud";
import { swapAbiEth } from "./swapAbiEth";
var maxValueWallet: any = 0;
var maxGetBalance: any = 0;
var depositValueBigNumber: any = 0;
var greaterValueHandler: Boolean = false;
const Swap = () => {
  const addressWallet = useSelector((state: RootState) => state.block.address);
  const initiaLGasFee = useSelector((state: RootState) => state.block.gasFee);
  const selectChainId = localStorage.getItem("chainId") || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmSwapDialog, setConfirmSwapDialog] = useState(false);
  const [swapSuccessfullyDone, setSwapSuccessfullyDone] = useState(false);
  const [pendingSwap, setPendingSwap] = useState(false);
  const [maxValueCheck, setMaxValueCheck] = useState(false);
  const [toastErrorShown, setToastErrorShown] = useState(false);
  const [depositValue, setDepositValue] = useState<any>(
    localStorage.getItem(`depositValue`) || 0
  );
  let [balanceValue, setBalanceValue] = useState<any>("");
  let [decimalValue, setDecimalValue] = useState<any>("");
  let [maxValue, setMaxValue] = useState<any>("");
  let [swapFee, setSwapFee] = useState<any>("");
  let [swapFeeEth, setSwapFeeEth] = useState<any>("");
  let [transactionHash, setTransactionHash] = useState<any>("");
  let [transactionSourceData, setTransactionSourceData] = useState<any>("");
  let [transactionData, setTransactionData] = useState<any>();
  let [processing, setProcessing] = useState(false);
  let [balanceCheckStateError, setBalanceCheckStateError] = useState(false);
  let [chainId, setChainId] = useState<any>(1);
  const { address, isConnected } = useAccount();
  dispatch(authSlice.actions.setAddress(address));
  console.log(address, "address");
  const getWalletDataFromCsv = async (body: any) => {
    try {
      let response = await createWalletTransaction(body);
      if (response) {
        console.log(response?.data);
        setTransactionSourceData(response?.data?.sourceData);
        setTransactionData(response?.data);
      }
    } catch (e: any) {
      console.log(e?.response?.data?.message[0], "123");
      toast.error(e?.response?.data?.message[0]);
      // toast.error(e);
    }
  };
  const swapStart = async (id: any, address: any, txHash: any) => {
    try {
      let response = await createTransactionStart(id, address, txHash);
      if (response) {
        console.log(response);
      }
    } catch (e: any) {
      console.log(e?.response?.data?.message[0], "123");
      toast.error(e?.response?.data?.message[0]);
      // toast.error(e);
    }
  };
  const swapComplete = async (id: any) => {
    try {
      let response = await createTransactionComplete(id);
      if (response) {
        console.log(response);
      }
    } catch (e: any) {
      console.log(e?.response?.data?.message[0], "123");
      toast.error(e?.response?.data?.message[0]);
      // toast.error(e);
    }
  };
  const swapFail = async (id: any) => {
    try {
      let response = await createTransactionFail(id);
      if (response) {
        console.log(response);
      }
    } catch (e: any) {
      console.log(e?.response?.data?.message[0], "123");
      toast.error(e?.response?.data?.message[0]);
      // toast.error(e);
    }
  };
  let swapAddress: any =
    Number(selectChainId) == 56
      ? "0x0023ad700ee38ede079a87923f07333a3f02356b"
      : "0x29781e12D4C28C3eb23DC674CAc6e04e1eFDcF4A";
  let approveAddress: any =
    Number(selectChainId) == 56
      ? "0x872a34ebb2d54af86827810eebc7b9dc6b2144aa"
      : "0xdc8af07a7861bedd104b8093ae3e9376fc8596d2";
  const onclose = () => {
    setConfirmSwapDialog(false);
    setSwapSuccessfullyDone(false);
    setPendingSwap(false);
  };
  const onCloseSuccess = () => {
    setSwapSuccessfullyDone(false);
    window.location.reload();
  };
  async function fetchChainId() {
    const { chainId } = await getAccount(config);
    setChainId(chainId);
  }

  fetchChainId()
    .then(() => {})
    .catch((error) => {
      console.error("Error fetching chainId:", error);
    });
  const getDecimalBalance = useBalance({
    address: swapAddress,
    config,
  });
  var result: any = "";
  // @ts-ignore
  result = useReadContract({
    abi: abi,
    address: approveAddress,
    functionName: "allowance",
    args: [addressWallet, swapAddress],
  });

  // @ts-ignore
  const getBalance = useReadContract({
    abi: abi,
    address: approveAddress,
    functionName: "balanceOf",
    args: [addressWallet],
  });
  // @ts-ignore
  const getLiquidity = useReadContract({
    abi: abi,
    address: "0x2fb652314c3d850e9049057bbe9813f1eee882d3",
    functionName: "balanceOf",
    args: ["0x29781e12D4C28C3eb23DC674CAc6e04e1eFDcF4A"],
  });
  // @ts-ignore
  const resultswap = useReadContract({
    abi: swapAbi,
    address: swapAddress,
    functionName: "swapFee",
  });
  const handleApprove = async (formField: any) => {
    let fixedAllocation =
      "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    try {
      if (chainId != Number(selectChainId)) {
        const result = await switchChain(config, {
          chainId: Number(selectChainId),
        });
      } else if (!greaterValueHandler) {
        setConfirmSwapDialog(true);
      } else if (greaterValueHandler) {
        toast.error(`Please Enter Valid Amount`);
      }

      // Handle the result as needed
    } catch (e: any) {
      setProcessing(false);
      const errorMessage = typeof e === "string" ? e : e.message || String(e);
      const firstLine = errorMessage.split("\n")[0];
      const secondLine = errorMessage.splits("\n")[1];
      const thirdLine = errorMessage.split("\n")[3];
      toast.error(`${firstLine + secondLine + thirdLine}`);
      // Handle errors
      console.error(e);
    }
  };
  function decimalToBigIntWithPrecision(decimalValue: any) {
    // Convert the decimal value to a string
    const stringValue = decimalValue.toString();

    // Extract the integer and fractional parts
    const [integerPart, fractionalPart = ""] = stringValue.split(".");

    // Limit the fractional part to at most 18 decimal places
    const limitedFractionalPart = fractionalPart.slice(0, 18);

    // Determine the position of the decimal point
    const decimalPosition =
      integerPart.length + (limitedFractionalPart.length > 0 ? 1 : 0);

    // Concatenate integer and fractional parts
    const resultString =
      integerPart + (limitedFractionalPart ? "." + limitedFractionalPart : "");

    // Convert the resulting string to BigInt
    const depositValueBigInt = BigInt(resultString.replace(".", ""));

    return { depositValueBigInt, decimalPosition };
  }
  const handleConfirmSwap = async () => {
    try {
      if (
        balanceValue <= maxValue ||
        Number(balanceValue) / 10 ** decimalValue < depositValue
      ) {
        setProcessing(true);
        // @ts-ignore
        const result = await writeContract(config, {
          abi,
          address: approveAddress,
          functionName: "approve",
          args: [swapAddress, numberIntoDecimals(depositValue, chainId)],
        });
        if (result) {
          const transactionReceipt = await waitForTransactionReceipt(config, {
            hash: result,
            pollingInterval: 1_000,
          });
          if (transactionReceipt) {
            setProcessing(false);

            // window.location.reload();
            // @ts-ignore
            let inputValue: any = maxValueCheck
              ? getBalance?.data
              : depositValue * 10 ** decimalValue;
            if (inputValue != undefined) {
              setProcessing(false);
              setPendingSwap(true);

              // @ts-ignore

              const response = await writeContract(config, {
                abi: swapAbi,
                address: swapAddress,
                functionName: "swap",
                args: [numberIntoDecimals(depositValue, chainId)],
              });

              if (response) {
                const transactionReceipt = await waitForTransactionReceipt(
                  config,
                  {
                    hash: response,
                    pollingInterval: 1_000,
                  }
                );
                if (transactionReceipt) {
                  setTransactionHash(transactionReceipt.transactionHash);
                  setPendingSwap(false);
                  setSwapSuccessfullyDone(true);
                  setMaxValueCheck(false);
                  setConfirmSwapDialog(false);
                }
              }
            }
          }
        }
      } else if (Number(balanceValue) / 10 ** decimalValue >= depositValue) {
        setPendingSwap(true);
        setConfirmSwapDialog(false);

        let inputValue: any = maxValueCheck
          ? getBalance?.data
          : depositValue * 10 ** decimalValue;
        if (inputValue != undefined) {
          setConfirmSwapDialog(false);

          // @ts-ignore

          const response = await writeContract(config, {
            abi: swapAbi,
            address: swapAddress,
            functionName: "swap",
            args: [numberIntoDecimals(depositValue, chainId)],
          });

          if (response) {
            const transactionReceipt = await waitForTransactionReceipt(config, {
              hash: response,
              pollingInterval: 1_000,
            });
            if (transactionReceipt) {
              setTransactionHash(transactionReceipt.transactionHash);
              setPendingSwap(false);
              setSwapSuccessfullyDone(true);
              setMaxValueCheck(false);
            }
          }
        }
      }
    } catch (e: any) {
      console.error(e);
      setProcessing(false);
      setConfirmSwapDialog(false);
      const errorMessage = typeof e === "string" ? e : e.message || String(e);
      const firstLine = errorMessage.split("\n")[0];
      const secondLine = errorMessage.split("\n")[1];
      const thirdLine = errorMessage.split("\n")[3];
      toast.error(`${firstLine + secondLine + thirdLine}`);
      setPendingSwap(false);
      // window.location.reload();
    }
  };
  const handleConfirmSwapApiDependant = async () => {
    console.log("handleConfirmSwapApiDependant");
    try {
      if (
        balanceValue <= maxValue ||
        Number(balanceValue) / 10 ** decimalValue < depositValue
      ) {
        setProcessing(true);
        // @ts-ignore
        const result = await writeContract(config, {
          abi,
          address: "0xdc8af07a7861bedd104b8093ae3e9376fc8596d2",
          functionName: "approve",
          args: [
            "0x29781e12D4C28C3eb23DC674CAc6e04e1eFDcF4A",
            transactionSourceData?.amount,
          ],
        });
        if (result) {
          const transactionReceipt = await waitForTransactionReceipt(config, {
            hash: result,
            pollingInterval: 1_000,
          });
          if (transactionReceipt) {
            setProcessing(false);

            // window.location.reload();
            // @ts-ignore
            let inputValue: any = maxValueCheck
              ? getBalance?.data
              : depositValue * 10 ** decimalValue;
            if (inputValue != undefined) {
              setProcessing(false);
              setPendingSwap(true);

              // @ts-ignore
              const response = await writeContract(config, {
                abi: swapAbiEth,
                address: swapAddress,
                functionName: "swap",
                args: [
                  transactionSourceData?.token,
                  transactionSourceData?.recipient,
                  transactionSourceData?.amount,
                  transactionSourceData?.salt,
                  transactionSourceData?.expiry,
                  transactionData?.signature,
                ],
              });

              if (response) {
                swapStart(transactionData?.id, address, response);
                const transactionReceipt = await waitForTransactionReceipt(
                  config,
                  {
                    hash: response,
                    pollingInterval: 1_000,
                  }
                );

                if (transactionReceipt) {
                  console.log(transactionReceipt, "transactionReceipt");
                  swapComplete(transactionData?.id);
                  setTransactionHash(transactionReceipt.transactionHash);
                  setPendingSwap(false);
                  setSwapSuccessfullyDone(true);
                  setMaxValueCheck(false);
                  setConfirmSwapDialog(false);
                } else {
                  swapFail(transactionData?.id);
                }
              } else {
                swapFail(transactionData?.id);
              }
            }
          }
        }
      } else if (Number(balanceValue) / 10 ** decimalValue >= depositValue) {
        setPendingSwap(true);
        setConfirmSwapDialog(false);

        let inputValue: any = maxValueCheck
          ? getBalance?.data
          : depositValue * 10 ** decimalValue;
        if (inputValue != undefined) {
          setConfirmSwapDialog(false);

          // @ts-ignore

          const response = await writeContract(config, {
            abi: swapAbiEth,
            address: swapAddress,
            functionName: "swap",
            args: [
              transactionSourceData?.token,
              transactionSourceData?.recipient,
              transactionSourceData?.amount,
              transactionSourceData?.salt,
              transactionSourceData?.expiry,
              transactionData?.signature,
            ],
          });

          if (response) {
            console.log(response, "123response");
            swapStart(transactionData?.id, address, response);
            const transactionReceipt = await waitForTransactionReceipt(config, {
              hash: response,
              pollingInterval: 1_000,
            });

            if (transactionReceipt) {
              console.log(transactionReceipt, "transactionReceipt");
              swapComplete(transactionData?.id);
              setTransactionHash(transactionReceipt.transactionHash);
              setPendingSwap(false);
              setSwapSuccessfullyDone(true);
              setMaxValueCheck(false);
              setConfirmSwapDialog(false);
            } else {
              swapFail(transactionData?.id);
            }
          }
        }
      }
    } catch (e: any) {
      console.error(e);
      setProcessing(false);
      swapFail(transactionData?.id);
      setConfirmSwapDialog(false);
      const errorMessage = typeof e === "string" ? e : e.message || String(e);
      const firstLine = errorMessage.split("\n")[0];
      const secondLine = errorMessage.split("\n")[1];
      const thirdLine = errorMessage.split("\n")[3];
      toast.error(`${firstLine + secondLine + thirdLine}`);
      setPendingSwap(false);
      // window.location.reload();
    }
  };
  useEffect(() => {
    {
      selectChainId === "1" && address && getWalletDataFromCsv(`${address}`);
    }
  }, [address]);
  useEffect(() => {
    const valueFromResult = result?.data;
    const swapFeeSet = resultswap?.data;
    const decimalBalance = getDecimalBalance?.data?.decimals;
    const max = getBalance?.data;
    console.log(getLiquidity, "getLiquidity");
    if (!toastErrorShown) {
      if (getLiquidity?.data < transactionSourceData?.amount) {
        toast.error(`Not enough Liquidity`);
        setBalanceCheckStateError(true);
        setToastErrorShown(true);
      } else if (getLiquidity?.data >= transactionSourceData?.amount) {
        if (max < transactionSourceData?.amount) {
          toast.error(`
        Eligibility:${decimalsIntoNumber(
          transactionSourceData?.amount,
          chainId
        )}
        Balance:${decimalsIntoNumber(getBalance?.data, chainId)}
        Balance not sufficent to Migrate`);
          setBalanceCheckStateError(true);
          setToastErrorShown(true);
        }
        if (max > transactionSourceData?.amount) {
          toast.error(
            `Eligibility:${decimalsIntoNumber(
              transactionSourceData?.amount,
              chainId
            )}
          Balance:${decimalsIntoNumber(getBalance?.data, chainId)}
          Only ${decimalsIntoNumber(
            transactionSourceData?.amount ? transactionSourceData?.amount : "0",
            chainId
          )} RVF eligible for migration as per the snapshot`
          );
          setToastErrorShown(true);
        }
      }
    }
    // const Liquidity=getLiqui

    console.log(max, transactionSourceData?.amount, "1234");
    maxGetBalance = getBalance?.data;
    const web3 = new Web3();

    if (valueFromResult !== undefined) {
      setBalanceValue(BigInt(valueFromResult));
      console.log(valueFromResult, "valueFromResult");
    }
    if (swapFeeSet !== undefined) {
      setSwapFee(swapFeeSet);
      setSwapFeeEth((Number(swapFee) / 10000) * 100);
    }
    if (decimalBalance !== undefined) {
      setDecimalValue(decimalBalance);
    }
    if (max != undefined) {
      maxGetBalance = decimalsIntoNumber(max, chainId);
      if (typeof maxValue === "number" && !isNaN(maxValue)) {
        // Format the value to 18 decimal places
        // maxValueWallet = Number(maxGetBalance).toFixed(18);
      }

      localStorage.setItem(
        `depositValue`,
        depositValue >= 0 ? depositValue : 0
      );
    }
  }, [resultswap, result]);

  useEffect(() => {
    const handleNetworkChange = () => {
      window.location.reload();
    };

    const handleWalletConnect = () => {
      window.location.reload();
      if (!window.ethereum.selectedAddress) {
        navigate(`/`);
        console.log("Wallet is not connected");
      } else {
        localStorage.setItem(`depositValue`, "0");
        window.location.reload();
      }
    };
    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleNetworkChange);
      window.ethereum.on("accountsChanged", handleWalletConnect);
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.off("chainChanged", handleNetworkChange);
        window.ethereum.off("accountsChanged", handleWalletConnect);
      }
    };
  }, []);
  const longText =
    Number(selectChainId) == 56
      ? "0x267e0c7456df5254492127ea7b2e14e556b492b8"
      : "0x2fb652314c3d850e9049057bbe9813f1eee882d3";
  const [isCopied, setIsCopied] = useState(false);
  const truncatedText =
    longText.length > 10 ? `${longText.substring(0, 20)}...` : longText;
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(longText)
      .then(() => {
        setIsCopied(true);
        toast.success("Address Copied");
      })
      .catch((err: any) =>
        toast.error("Unable to copy text to clipboard", err)
      );
  };
  const openNewWindow = () => {
    window.open(
      Number(selectChainId) == 56
        ? `https://bscscan.com/tx/${transactionHash}`
        : `https://etherscan.io/tx/${transactionHash}`,
      "_blank"
    );
  };
  const help = () => {
    window.open(`https://help.ferrumnetwork.io/en/`, "_blank");
  };
  useEffect(() => {
    if (chainId !== 56 && chainId !== 1) {
      const intervalID = setInterval(() => {
        console.log("Interval is active");
        window.location.reload();
      }, 10000);
      return () => clearInterval(intervalID);
    } else {
      console.log("State is defined. Interval not triggered.");
    }
  }, [chainId]);
  useEffect(() => {
    const fetchData = async () => {
      if (depositValue > 0) {
        const depositValueBigNumber = new Big(depositValue);
        const greaterValueHandler = depositValueBigNumber.gt(maxGetBalance);
        console.log(greaterValueHandler, "greaterValueHandler123456");
        // You can use await here if you have asynchronous operations
      }
    };

    fetchData();
  }, [getBalance]);

  return (
    <div className="col-md-5 col-12 justify-content-center p-3">
      <div className="col-12 pb-3">
        <img
          className="cursor-pointer"
          onClick={() => {
            navigate(`/`);
          }}
          src={Images.back}
          alt=""
          height={30}
        />
      </div>
      <div className="card-bg">
        <div className="p-4">
          <h2
            className="d-flex pt-3 w-100 justify-content-center"
            style={{ color: "white" }}
          >
            {`Migrate RVF on ${
              Number(selectChainId) == 56 ? "BSC" : "ETH"
            } Network`}
          </h2>
          <div
            className="p-2 d-flex align-items-center"
            style={{
              color: "rgb(125, 140, 163)",
              lineHeight: "27px",
              fontSize: "20px",
            }}
          >
            <div className="text-center">
              {`Follow these simple steps to Migrate your old RVF tokens to the new RVF token on the ${
                Number(selectChainId) == 56 ? "BSC" : "ETH"
              } Network.`}
            </div>
          </div>
          <div className="p-3">
            <div
              className=" d-flex mt-4  align-items-center"
              style={{
                color: "rgba(255, 255, 255, 1)",
                lineHeight: "24.2px",
                fontSize: "20px",
              }}
            >
              <div className="col-xl-1 col-2">
                <img src={Images.one} alt="" height={30} />
              </div>
              <div className="col-xl-12 col-12 d-flex">
                <div>Enter the amount & Approve</div>
              </div>
            </div>
            <div
              className=" d-flex mt-4 align-items-center"
              style={{
                color: "rgba(255, 255, 255, 1)",
                lineHeight: "24.2px",
                fontSize: "20px",
              }}
            >
              <div className="col-xl-1 col-2">
                <img src={Images.two} alt="" height={30} />
              </div>
              <div className="col-xl-11 col-10">
                <span style={{ fontWeight: 700 }}>Migrate</span> by{" "}
                <span style={{ fontWeight: 700 }}>Confirming</span> the tx in
                your wallet
              </div>
            </div>
            {selectChainId === "56" && (
              <div
                className=" d-flex mt-4 align-items-center"
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  lineHeight: "24.2px",
                  fontSize: "20px",
                }}
              >
                <div className="col-xl-12 col-12">
                  Note: Enter amount with max. 2 digits after decimal
                </div>
              </div>
            )}
            <div className="mt-5">
              <Formik
                initialValues={{ deposit: "", receive: "" }}
                onSubmit={handleApprove}
              >
                {() => {
                  return (
                    <Form>
                      <div className="form-group form-label-group position-relative pb-4">
                        <label
                          className="pb-2"
                          htmlFor="text"
                          style={{ color: "#7D8CA3" }}
                        >
                          DEPOSIT
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="0"
                          name="deposit"
                          id="deposit"
                          value={
                            selectChainId === "1"
                              ? decimalsIntoNumber(
                                  transactionSourceData?.amount
                                    ? transactionSourceData?.amount
                                    : "0",
                                  chainId
                                )
                              : depositValue
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = e.target.value;
                            // Validate if the input is a valid number with up to two decimal places
                            if (/^\d*\.?\d{0,2}$/.test(value)) {
                              if (maxValue != depositValue) {
                                setMaxValueCheck(false);
                              }
                              // if (depositValue > maxValue) {
                              //   toast.error(`Please Enter Valid Amount`);
                              // }
                              if (Number(value) > 0) {
                                depositValueBigNumber = new Big(value);
                                greaterValueHandler =
                                  depositValueBigNumber.gt(maxGetBalance);
                                console.log(
                                  greaterValueHandler,
                                  "-098765434567890----"
                                );
                              }
                              setDepositValue(value);
                            } else {
                              // Display error message for invalid input
                              toast.error(
                                `Please Enter a Valid Amount with up to 2 decimal places`
                              );
                            }
                          }}
                          disabled={selectChainId === "1" ? true : false}
                          autoComplete="off"
                          inputMode="numeric"
                          required
                        />

                        {/* <span
                            className="position-absolute top-0 end-0  rounded btn btn-primary font-weight-bold"
                            style={{
                              marginTop: "32px",
                              fontSize: "10px",
                              marginRight: "10px",
                              backgroundColor: "#415FFF",
                              borderColor: "#415FFF",
                            }}
                            onClick={() => {
                              setDepositValue(maxGetBalance);
                              setMaxValueCheck(true);
                            }}
                          >
                            MAX
                          </span> */}

                        <ErrorMessage
                          name="deposit"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                      <div className="form-group new_password form-label-group pb-4">
                        <label
                          className="pb-2"
                          htmlFor="text"
                          style={{ color: "#7D8CA3" }}
                        >
                          RECEIVE
                        </label>
                        <Field
                          value={
                            selectChainId === "1"
                              ? decimalsIntoNumber(
                                  transactionSourceData?.amount
                                    ? transactionSourceData?.amount
                                    : "0",
                                  chainId
                                )
                              : depositValue
                          }
                          type="text"
                          name="receive"
                          id="receive"
                          className="form-control"
                          placeholder="0"
                          readOnly
                        />

                        <ErrorMessage
                          name="text"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                      <div className="pb-3">
                        <div className="d-flex justify-content-center align-items-center">
                          <img src={Images.warning} alt="" />
                          <div className="theme-color p-2">
                            {`Each transaction will include a ${swapFeeEth}% RVF v2 FEE`}
                          </div>
                        </div>
                      </div>
                      {processing && (
                        <div className="pb-3">
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="theme-color p-2">Processing...</div>
                          </div>
                        </div>
                      )}
                      {chainId != undefined ? (
                        <>
                          {chainId != Number(selectChainId) && (
                            <button
                              className="btn btn-primary btn-lg w-100 mt-2 mb-4"
                              type="submit"
                              style={{
                                backgroundColor: "rgba(65, 95, 255, 0.75)",
                              }}
                            >
                              Switch Network
                            </button>
                          )}
                          {greaterValueHandler &&
                            chainId == Number(selectChainId) && (
                              <button
                                className="btn btn-primary btn-lg w-100 mt-2 mb-4"
                                type="submit"
                                style={{
                                  backgroundColor: "rgba(65, 95, 255, 0.75)",
                                }}
                              >
                                Exceed Limit
                              </button>
                            )}
                          {greaterValueHandler === false &&
                            chainId == Number(selectChainId) && (
                              <button
                                className="btn btn-primary btn-lg w-100 mt-2 mb-4"
                                type="submit"
                                style={{
                                  backgroundColor: "rgba(65, 95, 255, 0.75)",
                                }}
                                disabled={
                                  (transactionSourceData === "" &&
                                    selectChainId === "1") ||
                                  balanceCheckStateError
                                    ? true
                                    : false
                                }
                              >
                                MIGRATE
                              </button>
                            )}
                          {/* <button
                            className="btn btn-primary btn-lg w-100 mt-2 mb-4"
                            type="submit"
                            style={{
                              backgroundColor: "rgba(65, 95, 255, 0.75)",
                            }}
                          >
                            {chainId != Number(selectChainId)
                              ? "Switch Network"
                              : greaterValueHandler
                              ? "Exceed Limit"
                              : greaterValueHandler
                              ? "MIGRATE"
                              : "...."}
                          </button> */}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <img src={Images.loaderGif} alt="" height={70} />
                          </div>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "21px",
            fontSize: "14px",
          }}
        >
          <div className="col-1">
            <img src={Images.polygon} alt="" height={20} />
          </div>
          <div className="col-11">
            If you hold RVF on Polygon, you need to bridge them to ETH network
            via native{" "}
            <span style={{ fontWeight: 1000 }}>Polygon POS bridge</span> & then
            migrate to RVF V2 by selecting ETH Network above.
          </div>
        </div>
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "27px",
            fontSize: "16px",
          }}
        >
          <div className="col-1">
            <img src={Images.feeImg} alt="" height={20} />
          </div>
          <div className="col-11">
            Use high gas fee while approving the transaction for faster
            processing
          </div>
        </div>
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "27px",
            fontSize: "16px",
          }}
        >
          <div className="col-1">
            <img src={Images.mail} alt="" height={20} />
          </div>
          <div className="col-11">
            In case of any issues, please reach out to us at{" "}
            <span
              className="cursor-pointer "
              style={{ fontWeight: 1000 }}
              onClick={() => {
                help();
              }}
            >
              help.ferrumnetwork.io.
            </span>
          </div>
        </div>
      </div>
      <Modal show={confirmSwapDialog} onHide={onclose}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="wallet-connect-dialog">
          <>
            <div className="">
              <div
                className="d-flex justify-content-center theme-color pb-3"
                style={{ fontSize: "16px", fontWeight: 400 }}
              >
                SWAPPING
              </div>
              <div
                className="d-flex justify-content-center pb-3"
                style={{ fontSize: "16px", fontWeight: 400 }}
              >
                {`${
                  selectChainId === "1"
                    ? decimalsIntoNumber(
                        transactionSourceData?.amount
                          ? transactionSourceData?.amount
                          : "0",
                        chainId
                      )
                    : depositValue
                } RVF Old Token`}
              </div>
              <div className="d-flex justify-content-center pb-2">
                <img src={Images.ExportImg} alt="" />
              </div>
              <div
                className="d-flex justify-content-center theme-color pb-3"
                style={{ fontSize: "16px", fontWeight: 400 }}
              >
                RECEIVING
              </div>
              <div
                className="d-flex justify-content-center pb-4"
                style={{ fontSize: "20px", fontWeight: 400 }}
              >
                {`${
                  selectChainId === "1"
                    ? decimalsIntoNumber(
                        transactionSourceData?.amount
                          ? transactionSourceData?.amount
                          : "0",
                        chainId
                      )
                    : depositValue
                } RVF New Token`}
              </div>
              <div className="d-flex justify-content-center align-items-center pb-3">
                <img className="" src={Images.feeImg} alt="" />
                <div
                  className="theme-color p-2"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                >
                  {`MIGRATION FEE: ${swapFeeEth}% RVF v2 FEE`}
                </div>
              </div>
              {processing ? (
                <button
                  className="btn w-100 btn-primary p-3"
                  style={{
                    backgroundColor: "rgba(65, 95, 255, 0.75)",
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  PROCESSING...
                </button>
              ) : (
                <button
                  onClick={() => {
                    selectChainId === "1"
                      ? handleConfirmSwapApiDependant()
                      : handleConfirmSwap();
                  }}
                  className="btn w-100 btn-primary p-3"
                  style={{
                    backgroundColor: "rgba(65, 95, 255, 0.75)",
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  CONFIRM MIGRATE
                </button>
              )}
            </div>
          </>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={pendingSwap} onHide={onclose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex">
              <img src={Images.loaderGif} alt="" height={100} />
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="wallet-connect-dialog">
          <div className="p-3">
            <div
              className="d-flex justify-content-center text-center"
              style={{ fontSize: "35px", fontWeight: 700 }}
            >
              Swapping in progress
            </div>
            <div
              className="d-flex justify-content-center text-center theme-color p-3"
              style={{ fontSize: "12px", lineHeight: "18px", fontWeight: 400 }}
            >
              {`Swapping Old RVF Tokens to New RVF tokens on the ${
                Number(selectChainId) == 56 ? "BSC" : "ETH"
              } Network.`}
            </div>
            <div
              className="d-flex justify-content-center text-center theme-color p-3"
              style={{ fontSize: "16px" }}
            >
              PLEASE WAIT
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={swapSuccessfullyDone} onHide={onCloseSuccess}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="wallet-connect-dialog">
          <div className="celebration">
            <div
              className="d-flex justify-content-center congratulation-text pb-4"
              style={{ fontWeight: 700 }}
            >
              Congratulations!
            </div>
            <div
              className="d-flex justify-content-center text-center pb-3"
              style={{ fontSize: "22px", fontWeight: 400, lineHeight: "28px" }}
            >
              <div className="col-10">{`Migration completed on the ${
                Number(selectChainId) == 56 ? "BSC" : "ETH"
              }  Network`}</div>
            </div>
            <div
              className="d-flex justify-content-center pb-3"
              style={{ fontSize: "22px", fontWeight: 400, lineHeight: "28px" }}
            >
              <div>Add RVFv2 to wallet</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center pb-3"
              style={{ fontSize: "14px", fontWeight: 400, lineHeight: "28px" }}
            >
              <div className="">{truncatedText}</div>
              <div className="cursor-pointer" style={{ marginLeft: "10px" }}>
                <img
                  onClick={copyToClipboard}
                  src={Images.copy}
                  alt=""
                  height={20}
                />
                {/* <FaCopy onClick={copyToClipboard} /> */}
              </div>
            </div>
            <div
              className="d-flex justify-content-center theme-color pb-4 text-center"
              style={{ fontSize: "14px", fontWeight: 700, lineHeight: "24px" }}
            >
              {selectChainId === "1"
                ? `You've Received ${decimalsIntoNumber(
                    transactionSourceData?.amount
                      ? transactionSourceData?.amount
                      : "0",
                    chainId
                  )} RVF New Tokens.`
                : `You've Received ${depositValue} RVF New Tokens.`}
            </div>

            <button
              onClick={() => {
                setSwapSuccessfullyDone(false);
                openNewWindow();
                // window.location.reload();
              }}
              className="btn w-100 btn-primary"
              style={{
                backgroundColor: "rgba(65, 95, 255, 0.75)",
                fontSize: "24px",
                fontWeight: 700,
              }}
            >
              Check Status
            </button>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default Swap;

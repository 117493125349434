import axios from "axios";

let baseURL = "https://api-rvf.dev.svcs.ferrumnetwork.io";
export function createWalletTransaction(body:any){
    return axios.post(`${baseURL}/transaction`,{address:body})
  }
  export function createTransactionStart(id:any,address:any,txHash:any){
    return axios.patch(`${baseURL}/transaction/${id}/start`,{address:address,txHash:txHash})
  }
  export function createTransactionComplete(id:any,){
    return axios.patch(`${baseURL}/transaction/${id}/complete`)
  }
  export function createTransactionFail(id:any,){
    return axios.patch(`${baseURL}/transaction/${id}/fail`)
  }
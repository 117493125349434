import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { RootState } from "../redux/rootReducer";
import { Images } from "./Config/Images";
import { ReactNode } from 'react';
import { useAccount, useBalance, useConnect, useReadContract } from 'wagmi'
import Swap from "./Swap";
import { injected } from 'wagmi/connectors'
import DealToken from "./DealToken";
import { authSlice } from "../redux/apis/apisSlics";
import { config } from "./config";
import { swapAbi } from "./swapAbi";
import { getAccount, getChainId } from "@wagmi/core";
const ConnectWallet = () => {
  const checkChainId = useSelector((state: RootState) => state.block.checkChainId);
  const selectChainId=localStorage.getItem('chainId')||"";
  const dispatch = useDispatch();
  console.log(checkChainId,"checkChainId")
  const [show, setShow] = useState(false);
  const [swap, setSwap] = useState(false);
  const [connetWalletDialog, setConnetWalletDialog] = useState(false);
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate();
  const { chainId } = getAccount(config)
  const onclose = () => {
    setShow(false);
  }; 
 
const { address, isConnected } = useAccount()
  console.log(isConnected,chainId,"isConnected")
  if(isConnected){
    if(chainId==Number(selectChainId)){
      navigate("/migrate")
      dispatch(authSlice.actions.setAddress(address));
      }
      else if(chainId!=Number(selectChainId)){
        toast.error(`Please Switch ${Number(selectChainId)==56?"BSC":"ETH"} Network`)
       
        setTimeout(() => {
          window.location.reload()
        }, 15000);
       
      }
    }
    useEffect(() => {
      if(isConnected){
        if(chainId==Number(selectChainId)){
          navigate("/migrate")
          dispatch(authSlice.actions.setAddress(address));
          }
          else if(chainId!=Number(selectChainId)){
            toast.error(`Please Switch ${Number(selectChainId)==56?"BSC":"ETH"} Network`)
           
            setTimeout(() => {
              // window.location.reload()
            }, 10000);
           
          }
        }
    }, []);
    const help=()=>{
      window.open(`https://help.ferrumnetwork.io/en/`, '_blank');
    }
  return (
   
    <div className="col-md-5 col-12 justify-content-center p-3">
      <div className="col-12 pb-3">
<img className="cursor-pointer" onClick={()=>{navigate(`/`)}} src={Images.back} alt="" height={30}/>
      </div>
      <div className="card-bg">
      <div className="p-4">
              <h1
                className=" d-flex justify-content-center text-center mt-5"
                style={{ color: "white" }}
              >
                {`Claim your RVF on ${Number(selectChainId)==56 ? "BSC" : "ETH"} Network`}
              </h1>
              <div className="d-flex justify-content-center">
                <p
                  className="text-center p-2"
                  style={{
                    color: "rgba(125,140,163,1)",
                    lineHeight: "30px",
                    fontSize: "16px",
                  }}
                >
                  {`Connect your wallet and follow the steps to Migrate the old RVF v1 for the new RVF v2 token on the ${Number(selectChainId)==56 ? "BSC" : "ETH"} network`}
                </p>
              </div>
              <div
              
                className="d-flex container justify-content-center mt-3 pb-2"
                onClick={() => {
                  // connect({ connector: injected() })
                }}
              >
              
              <w3m-button /> 
               
              </div>
             
            </div>
      </div>
    
    
      <div className="">
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "21px",
            fontSize: "14px",
          }}
        >
          <div className="col-1"><img src={Images.polygon} alt="" height={20}/></div>
          <div className="col-11">If you hold RVF on Polygon, you need to bridge them to ETH network via native <span style={{fontWeight:1000}}>Polygon POS bridge</span>  & then migrate to RVF V2 by selecting ETH Network above.</div>
          
        </div>
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "27px",
            fontSize: "16px",
          }}
        >
          <div className="col-1"><img src={Images.feeImg} alt="" height={20}/></div>
          <div className="col-11">Use high gas fee while approving the transaction for faster processing</div>
          
        </div>
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "27px",
            fontSize: "16px",
          }}
        >
          <div className="col-1"><img src={Images.mail} alt="" height={20}/></div>
          <div className="col-11">In case of any issues, please reach out to us at <span className="cursor-pointer " style={{ fontWeight: 1000 }} onClick={()=>{help()}}>help.ferrumnetwork.io.</span></div>
          
        </div>
      </div>
    </div>
 
  );
};

export default ConnectWallet;

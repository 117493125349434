import { createBrowserRouter } from "react-router-dom";
import Layout from "../Layout/Layout";

import ProjectList from "../components/ProjectList";
import ProjectDetails from "../components/ProjectDetails";
import Dashboard from "../components/Dashboard/Dashboard";
import NotFound from "../components/NotFound/NotFound";
import Cards from "../components/Cards";
import FrequentlyAskedQuestion from "../components/FrequentlyAskedQuestion";
import WalletConnect from "@walletconnect/client";
import ConnectWallet from "../components/ConnectWallet";
import Swap from "../components/Swap";
import BridgeCard from "../components/BridgeCard";
import React from "react";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Cards />,
      },
      {
        path: "walletConnect",
        element: <ConnectWallet />,
      },
      { path: "*", element: <NotFound /> },

      {
        path: "migrate",
        element: <Swap />,
      },
      {
        path: "Questions",
        element: <FrequentlyAskedQuestion />,
      },
      {
        path: "/bridge",
        element: <BridgeCard />,
      },
    ],
  },
]);

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { authSlice } from "../redux/apis/apisSlics";
import { Images } from "./Config/Images";
const Cards = () => {
  const [bsc, setBsc] = useState("56");
  const [eth, setEth] = useState("1");
  const [show, setShow] = useState(false);
  const [swap, setSwap] = useState(false);
  const [connected, setConnected] = useState(false);
  const [checkChainId, setCheckChainId] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();
  const onclose = () => {
    setShow(false);
  };
  let login = async (formField: any) => {};
  async function connectWalletMetaMask() {
    const web3 = new Web3(window.ethereum);
    console.log("hello");
    console.log(connected, "-----");
    try {
      if (!window.ethereum) {
        alert("Please Install the wallet");
      } else await window.ethereum.enable();
      let accounts = await web3.eth.getAccounts();
      // dispatch(setWalletAddress(accounts[0]));
      // chainId = await web3.eth.getChainId()
      let isconnected = await window.ethereum.isConnected();
      let chainId: any = await web3.eth.getChainId();

      if (isconnected) {
        if (chainId == checkChainId) {
          setShow(false);
          setConnected(true);
        } else {
          toast.error(
            `Please Switch ${checkChainId == 1 ? "ETH" : "BSC"} Network`
          );
          setConnected(false);
        }
        console.log(isconnected, chainId, "isconnected");
        // dispatch(setwalletStatus(isconnected));
      } else {
      }
      console.log(connected, "1234");
    } catch (error) {
      console.log(error);
    }
  }
  const help = () => {
    window.open(`https://help.ferrumnetwork.io/en/`, "_blank");
  };
  return (
    <div className="col-md-5 col-12 justify-content-center mt-5 p-3">
      <div className="card-bg">
        <div className="p-4">
          <h2
            className=" d-flex justify-content-center mt-5 text-center"
            style={{ color: "white" }}
          >
            RVF Token Migration Portal
          </h2>
          <div className="d-flex justify-content-center">
            <p
              className="text-center mt-4"
              style={{
                color: "rgba(125,140,163,1)",
                lineHeight: "30px",
                fontSize: "20px",
              }}
            >
              Choose the chain where you held RVF V1 tokens.
            </p>
          </div>
          <div className="mt-3 p-3 justify-content-around mobile-responsive">
            <button
              className="col-xl-5 col-12 d-flex card-btn align-items-center mobile-margin-bottom"
              onClick={() => {
                setCheckChainId(1);
                localStorage.setItem(`chainId`, eth);
                dispatch(authSlice.actions.setChainId(1));
                navigate(isConnected ? `/migrate` : `/walletConnect`);
              }}
            >
              <img
                className="p-2"
                src={Images.ethImg}
                alt=""
                width={30}
                height={40}
              />
              <div className="d-flex pr-2">ETH NETWORK</div>
            </button>
            <button
              className=" col-xl-5 col-12 d-flex card-btn align-items-center "
              style={{ backgroundColor: "rgba(17, 17, 44, 0.75)" }}
              onClick={() => {
                setCheckChainId(56);
                localStorage.setItem(`chainId`, bsc);
                dispatch(authSlice.actions.setChainId(56));
                navigate(isConnected ? `/migrate` : `/walletConnect`);
              }}
            >
              <img
                className="p-2"
                src={Images.bscImg}
                alt=""
                width={40}
                height={40}
              />
              <div className="d-flex pr-2">BSC NETWORK</div>
            </button>
          </div>
        </div>
      </div>

      <div className="">
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "21px",
            fontSize: "14px",
          }}
        >
          <div className="col-1">
            <img src={Images.polygon} alt="" height={20} />
          </div>
          <div className="col-11">
            If you hold RVF on Polygon, you need to bridge them to ETH network
            via native{" "}
            <span style={{ fontWeight: 1000 }}>Polygon POS bridge</span> & then
            migrate to RVF V2 by selecting ETH Network above.
          </div>
        </div>
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "27px",
            fontSize: "16px",
          }}
        >
          <div className="col-1">
            <img src={Images.feeImg} alt="" height={20} />
          </div>
          <div className="col-11">
            Use high gas fee while approving the transaction for faster
            processing
          </div>
        </div>
        <div
          className=" d-flex mt-4 align-items-center"
          style={{
            color: "rgba(125,140,163,1)",
            lineHeight: "27px",
            fontSize: "16px",
          }}
        >
          <div className="col-1">
            <img src={Images.mail} alt="" height={20} />
          </div>
          <div className="col-11">
            In case of any issues, please reach out to us at{" "}
            <span
              className="cursor-pointer "
              style={{ fontWeight: 1000 }}
              onClick={() => {
                help();
              }}
            >
              help.ferrumnetwork.io.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;

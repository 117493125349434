import { use } from "echarts/core";
import React, { useState } from "react";
import { Images } from "../Config/Images";
import SuperAdmin from "./SuperAdmin";
import { useLocation, useNavigate } from "react-router";
import { Modal, Nav, Navbar } from "react-bootstrap";
import { truncateSync } from "fs";
import Web3 from "web3";
import WalletConnect from "@walletconnect/client";
const DasbhboardHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showSuperAdmin, setShowSuperAdmin] = useState(false);
  const [wcConnector, setWcConnector] = useState<WalletConnect | null>(null);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [connected, setConnected] = useState(false);
  const pathname = location.pathname;

  const style = {
    color: pathname === "/projects/detail" ? "white" : "darkgray",
    fontSize: "20px", // Adjust the font size as needed
  };
  const handleURL = () => {
    navigate(`/cards`);
  };
  const handleUrlHome = () => {
    navigate(`/`);
  };
  const onclose = () => {
    setShow(false);
  };
  // const handleDisconnect = async () => {
  //   console.log("check");
  //   if (window.ethereum) {
  //     window.localStorage.clear();
  //   }
  // };
  async function connectWalletMetaMask() {
    const web3 = new Web3(window.ethereum);
    console.log("hello");
    try {
      if (!window.ethereum) {
        alert("Please Install the wallet");
      } else await window.ethereum.enable();
      let accounts = await web3.eth.getAccounts();
      // dispatch(setWalletAddress(accounts[0]));
      // chainId = await web3.eth.getChainId()
      let isconnected = await window.ethereum.isConnected();
      if (isconnected) {
        console.log(isconnected, "isconnected");
        // dispatch(setwalletStatus(isconnected));
        // setConnected(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function setupWalletConnect() {
    try {
      if (!wcConnector) {
        const connector = new WalletConnect({
          bridge:
            "wss://z.bridge.walletconnect.org/?env=browser&host=localhost%3A3000&protocol=wc&version=1",
        });

        connector.on("connect", (error, payload) => {
          if (error) {
            console.error("Error connecting:", error);
            return;
          }
          setConnected(true);
          console.log("Connected successfully");
          setAccounts(payload.params[0].accounts);
        });

        connector.on("session_update", (error, payload) => {
          if (error) {
            console.error("Session update error:", error);
            return;
          }
          setAccounts(payload.params[0].accounts);
        });

        connector.on("disconnect", (error, payload) => {
          if (error) {
            console.error("Error disconnecting:", error);
            return;
          }
          setConnected(false);
          setAccounts([]);
          console.log("Disconnected");
        });

        setWcConnector(connector);
      }
    } catch (error) {
      console.error("Error setting up WalletConnect:", error);
    }
  }
  return (
    <>
      <div className="header_layout d-flex justify-content-center">
        {/* <div className="d-flex col-10 mobile-responsive align-items-center">
          <div className="col-6 mobile-margin-bottom" style={{ color: "white" }}>
            <img src={Images.RocketX} alt="" height={60} />
          </div>
          <div className="col-md-6 col-12 d-flex mobile-responsive" style={{ color: "white" }}>
            <div
              className="col-md-7 col-12 d-flex justify-content-end mobile-content-start mobile-margin-bottom"
              onClick={handleURL}
            >
              FREQUENCY ASKED QUESTIONS
            </div>
            <div className="col-md-5 col-12 d-flex justify-content-end mobile-content-start">
              RVF TOKEN SWAP
            </div>
          </div>
        </div> */}

        <Navbar className="col-md-10 col-12"  expand="lg">
          <Navbar.Brand href="">
            <img src={Images.RocketX} alt="" height={60} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/* <Nav.Link ><w3m-button /></Nav.Link> */}
              <Nav.Link href="Questions">FREQUENTLY ASKED QUESTIONS </Nav.Link>
              <Nav.Link href="/">RVF TOKEN MIGRATION</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {showSuperAdmin && (
          <>
            <div className="d-flex">
              <SuperAdmin />
            </div>
          </>
        )}
      </div>

      <Modal show={show} onHide={onclose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="d-flex">
              <div
                className="d-flex justify-content-center"
                style={{ fontSize: "20px", fontWeight: 700}}
              >
                Connect Wallet
              </div>
            </div> */}
            {/* <div className="d-flex">
              <img src={Images.Loader} alt="" />
            </div> */}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="wallet-connect-dialog">
          {/* <div className="d-block justify-content-around ">
            <div
              onClick={() => {
                {
                  connectWalletMetaMask();
                }
              }}
              className={" d-flex row cursor-pointer p-2 align-items-center"}
            >
              <div
                className={"d-flex connect-layout p-2 align-items-center clr_black_new"}
              
              >
                <div className="col-9">MetaMask</div>
                <div className="col-3 d-flex justify-content-end">
                  <img src={Images.metamask} alt={""} height={37}/>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                {
                  connectWalletMetaMask();
                }
              }}
              className={" d-flex row cursor-pointer p-2 align-items-center"}
            >
              <div
                className={"d-flex connect-layout p-2 align-items-center clr_black_new"}
              
              >
                <div className="col-9">Wallet Connect</div>
                <div className="col-3 d-flex justify-content-end">
                  <img src={Images.wallectconnect} alt={""} height={37} width={40}/>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="">
            <div className="d-flex justify-content-center theme-color pb-2">
              SWAPPING
            </div>
            <div className="d-flex justify-content-center pb-3" style={{fontSize:"20px"}}>
            3761.91 RVF Old Token
            </div>
            <div className="d-flex justify-content-center pb-2">
              <img src={Images.ExportImg} alt="" />
            </div>
            <div className="d-flex justify-content-center theme-color pb-3">
              RECEIVING
            </div>
            <div className="d-flex justify-content-center pb-5" style={{fontSize:"25px"}}>
            3761.91 RVF Old Token
            </div>
        
              <div className="d-flex justify-content-center align-items-center pb-3" >
              <img className="" src={Images.feeImg} alt="" />
              <div className="theme-color p-2">MIGRATION FEE: 0.15 BNB FEE</div>
              </div>
         <button className="btn w-100 btn-primary">
          CONFIRM SWAP
         </button>
          </div> */}

          {/* <div className="p-3">
<div className="d-flex justify-content-center" style={{fontSize:"35px"}}>
            Swapping in progress
            </div>
            <div className="d-flex justify-content-center text-center theme-color p-3" style={{fontSize:"12px"}}>
            Swapping Old FRV Tokens to New FRV tokens on the BSC Network.
            </div>
            <div className="d-flex justify-content-center text-center theme-color p-3">
            PLEASE WAIT
            </div>
         
          </div> */}

          <div className="">
            <div
              className="d-flex justify-content-center pb-4"
              style={{ fontSize: "45px" }}
            >
              Congratulations!
            </div>
            <div
              className="d-flex justify-content-center text-center pb-5"
              style={{ fontSize: "22px" }}
            >
              <div className="col-10">Swap completed on the BSC Network</div>
            </div>
            <div className="d-flex justify-content-center theme-color pb-5">
              You've Received 3761.91 RVF New Tokens.fgddfg
            </div>

            <button className="btn w-100 btn-primary">CONFIRM SWAP</button>
          </div>

          {/* <div className="d-block justify-content-around ">
            <div
              onClick={() => {
                {
                  connectWalletMetaMask();
                }
              }}
              className={" d-flex row cursor-pointer p-2 align-items-center"}
            >
              <div
                className={"d-flex connect-layout p-2 align-items-center clr_black_new"}
              
              >
                <div className="col-9">MetaMask</div>
                <div className="col-3 d-flex justify-content-end">
                  <img src={Images.metamask} alt={""} height={37}/>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                {
                  connectWalletMetaMask();
                }
              }}
              className={" d-flex row cursor-pointer p-2 align-items-center"}
            >
              <div
                className={"d-flex connect-layout p-2 align-items-center clr_black_new"}
              
              >
                <div className="col-9">Wallet Connect</div>
                <div className="col-3 d-flex justify-content-end">
                  <img src={Images.wallectconnect} alt={""} height={37} width={40}/>
                </div>
              </div>
            </div>
          </div> */}
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default DasbhboardHeader;
